@import './colors.scss';
@import './fonts.scss';
@import './animations.scss';

$theme-colors: (
  "primary": $orange,
  "secondary": white,
);

@import "node_modules/bootstrap/scss/bootstrap";

.slide-down-anim-container {
  position: absolute;
  width: 100%;
  bottom: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  z-index: 200;

  color: white;

  pointer-events: none;

  &.hide {
    animation: fade-out 0.5s ease-out both;
  }

  .slide-down-anim-icon {
    margin: 0.5rem;
    font-size: 3rem;
    animation: slide-out-bottom 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) infinite both;
  }
}

body {
  height: 100vh;

  > #root {
    height: 100%;

    > .app {
      height: 100%;
      background-color: lighten($color: $dark, $amount: 10);
      font-family: 'Oswald', Arial, Helvetica, sans-serif;
    }
  }
}

.uppercase {
  text-transform: uppercase;
}

#fp-nav ul {
  li {
    margin: 2px 7px;

    a span {
      background: white!important;
      box-shadow: 0px 0px 6px 1px $dark;
    }

    a.active span {
      height: 8px;
      width: 8px;
      margin: -4px 0 0 -4px;
    }

    &:hover {
      a.active span {
        height: 8px;
        width: 8px;
        margin: -4px 0 0 -4px;
      }
    }
  }
}

#fp-nav.initialized-medias ul li:last-child a span {
  animation: ping 0.8s ease-in-out infinite both;
}

.fp-alt-nav {
  display: none;

  &.active {
    pointer-events: none;
    z-index: 1;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    padding-right: calc(17px + 7px); // fp-nav + li margins

    > * {
      color: white;
      text-shadow: 0 0 6px black;
    }
  }
}

.btn-link {
  color: white;
  text-decoration: underline;
  padding: 0;
  vertical-align: baseline;

  &:hover {
    color: white;
  }
}

.logo {
  z-index: 1;
  // max-width: 250px;
  margin-bottom: 3rem;
  filter: drop-shadow(0px 0px 5px rgba(0,0,0,1));
}

.logo-nav {
  max-height: 3rem;
}


.navbar {
  position: fixed;
  z-index: 1;

  width: 100%;
  height: 4rem;
  padding: 0;

  .navbar-brand {
    margin-right: 0;
  }
}

.text-field {
  > label {
    text-transform: capitalize;
    font-weight: 600;
  }
}

.initialized-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  color: white;

  > .title {
    font-size: 1.5rem;
  }

  a {
    color: white;
    text-decoration: underline;
    font-size: 1rem;
  }

  > .initialized-icon {
    margin-bottom: 1.5rem;
    max-width: 3rem;
    width: 100%;
    height: auto;
  }
}

.no-medias-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  color: white;

  > .no-medias-content {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
  }

  > .no-media-icon {
    font-size: 5rem;
  }

  > .title {
    font-size: 1.5rem;
  }

  a {
    color: white;
    text-decoration: underline;
    font-size: 1rem;
  }

  .no-medias-icon {
    margin-bottom: 1.5rem;
    max-width: 5rem;
    width: 100%;
    height: auto;
  }
}

.loading-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: white;

  > .loading-icon {
    font-size: 5rem;
  }

  > .title {
    font-size: 1.5rem;
    z-index: 1;
  }
}

.form-background {
  position: absolute;
  height: 100vh;
  width: 100vw;

  background: url('../assets/background.jpg');
  background-size: cover;
  background-position: left;

  // filter: contrast(0.7);
}

.form-container {
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: white;

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;

    z-index: 1;

    width: 100%;
  
    .form-group {
      width: 100%;
      max-width: 400px;

      .invalid-feedback {
        margin-top: 0;
        padding: 0.5rem;
        background-color: transparentize($color: $dark, $amount: 0.5);
        backdrop-filter: blur(10px);
        font-size: 1rem;
        text-shadow: 2px 2px 3px $dark;
        text-align: center;
      }
    }
  
    .input-group-append {
      > button {
        border-top-right-radius: 0.25rem!important;
        border-bottom-right-radius: 0.25rem!important;
      }
    }
  
    > button {
      position: relative;
      width: 100%;
      max-width: 400px;
      top: 0;
      opacity: 1;
      transition: all .3s ease-in-out;

      &.hide {
        top: 50px;
        opacity: 0;
      }

      &.disabled {
        background-color: #404040;
        border-color: #404040;
        top: 50px;
        opacity: 0;
      }
    }
  }
}

.gallery-item {

  .container {
    height: 100%;
  }

  .card {
    display: flex;
    height: 100%;

    border: none;
    background-color: transparent;

    .card-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      padding: 0;

      .gallery-item-content {
        display: flex;
        justify-content: center;
        width: 100%;

        > .video-item {
          max-width: 100%;
          height: auto;
        }

        > .image-item {
          max-width: 100%;
          height: auto;
          align-self: flex-start;
        }
      }

      .gallery-item-footer {
        width: 100vw;
        display: flex;
        flex-direction: column;

        position: absolute;
        bottom: 0;
      
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;

        color: white;

        background-color: transparentize($color: $dark, $amount: 0.65);
        backdrop-filter: blur(10px);

        border-top: 1px solid transparentize($color: lighten($color: $dark, $amount: 33), $amount: 0.5);
        border-bottom: 1px solid transparentize($color: lighten($color: $dark, $amount: 33), $amount: 0.5);

        .gallery-item-name {
          font-size: 1.5rem;
          text-shadow: 2px 2px 5px black;
        }

        .download-button {
          border: none;
          font-size: 1.5rem;
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }
}
