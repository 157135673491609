/**
 * ----------------------------------------
 * animation ping
 * ----------------------------------------
 */
 @-webkit-keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(2.2);
            transform: scale(2.2);
    opacity: 0;
  }
}
@keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(2.2);
            transform: scale(2.2);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes slide-out-bottom {
  5% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
    opacity: 0;
  }
}
@keyframes slide-out-bottom {
  5% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation fade-out
 * ----------------------------------------
 */
 @-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}